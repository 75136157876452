* {
  margin: 0;
  font-family: 'Nunito Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.react-datepicker {
  width: max-content;
  height: 21em;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
#map {
  height: 70vh;
  width: 100%;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px green;
  }
}
